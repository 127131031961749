import { isUndefined } from 'lodash';
import React from 'react';
import { FILTER_CELL_STYLE_RIGHT_ALIGNED } from '../../../consts/tableOptions';
import { translate } from '../../../i18n/I18nProvider';

import {
  mapCellNumbersToDisplay,
  mapCellNumbersToFilter
} from '../../../pages/dataLimits/actions/tableColumnsActions';
import CellNumbersTableCell from '../../../partials/CellNumbersTableCell/CellNumbersTableCell';
import removeWhiteSpaces from '../../../utils/removeWhiteSpaces';
import searchCaseInsensitive from '../../../utils/searchCaseInsensitive';
import searchInArray from '../../../utils/searchInArray';
import searchNumbers from '../../../utils/searchNumbers';
import { createMonthsArray } from '../actions/mapDatalimits';
import UsageCell from './UsageCell';
import Optional from '../../../utils/optional';

const AVG_COLUMN_COLOR = '#FAFAFA';
const tableColumns = ({ showAll, lastMonthsCount }) => {
  const [actualMonth, ...usageMonths] = createMonthsArray(lastMonthsCount + 1);

  const fairUsageTitle = translate('DATA_LIMITS.ROAMING_EU_GB');

  let columns = [
    {
      title: translate('TABLE.NAME'),
      customFilterAndSearch: (term, rowData) => {
        return searchCaseInsensitive(rowData.name, term);
      },
      field: 'name'
    },
    {
      title: translate('CELL_NUMBERS'),
      render: rowData => (
        <CellNumbersTableCell
          data={rowData}
          mapNumbers={mapCellNumbersToDisplay}
          showMore={true}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: mapCellNumbersToFilter(rowData),
          term: removeWhiteSpaces(term),
          isValueNumber: true
        }),
      align: 'right',
      sorting: false,
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },

    {
      title: translate('EDIT.USER'),
      field: 'user'
    },
    {
      title: (
        <div>
          <div>{`${translate('CURRENT')}`}</div>
          <div>{`${translate('DATA_LIMITS.DATA_LIMIT')} GB`}</div>
        </div>
      ),
      field: 'totalLimit',
      render: rowData => rowData.limit,
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: (
        <div>
          <div>{translate('USAGE_GB')}</div>
          <div>{actualMonth.date}</div>
        </div>
      ),
      field: 'actualMonthUsage.total_data',
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.actualMonthUsage.total_data_string, term);
      },
      render: rowData => (
        <UsageCell
          percentage={rowData.actualMonthUsage.totalPercentage}
          value={rowData.actualMonthUsage.total_data_string}
        />
      ),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    ...usageMonths.map((month, i) => {
      return {
        title: (
          <div>
            <div>{translate('USAGE_GB')}</div>
            <div>{month.date}</div>
          </div>
        ),
        customSort: (a, b) => {
          return sortUsage({ a, b, i, key: 'total_data' });
        },
        customFilterAndSearch: (term, rowData) => {
          return searchNumbers(
            rowData.lastMonthUsage[i].total_data_string,
            term
          );
        },
        render: rowData => (
          <UsageCell
            percentage={rowData.lastMonthUsage[i].totalPercentage}
            value={rowData.lastMonthUsage[i].total_data_string}
          />
        ),
        align: 'right',
        showAll: true,
        filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
      };
    }),

    {
      title: (
        <div>
          <div>{translate('DATA_LIMITS.AVERAGE_USE')}</div>
          <div>{`${translate('LAST')} ${
            showAll ? lastMonthsCount : '3'
          } ${translate('MONTHS_SHORT')}`}</div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          showAll
            ? rowData.totalAverage[lastMonthsCount - 1]
            : rowData.totalAverage[2],
          term
        );
      },
      customSort: (a, b) => {
        return sortAverage({
          a,
          b,
          key: 'totalAverage',
          index: showAll ? lastMonthsCount - 1 : 2
        });
      },
      field: showAll
        ? `totalAverage[${lastMonthsCount - 1}]`
        : `totalAverage[2]`,
      align: 'right',
      headerStyle: {
        backgroundColor: AVG_COLUMN_COLOR,
        '&:hover': {
          backgroundColor: 'pink'
        }
      },
      cellStyle: {
        backgroundColor: AVG_COLUMN_COLOR,
        '&:hover': {
          backgroundColor: 'pink'
        }
      },
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: (
        <div>
          <div>{`${translate('CURRENT')}`}</div>
          <div>{`${translate('DATA_LIMITS.ROAMING_EU_LIMIT')} GB`}</div>
        </div>
      ),
      field: 'otherLimit',
      render: rowData => rowData.fairLimit,
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: (
        <div>
          <div>{fairUsageTitle}</div>
          <div>{actualMonth.date}</div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(rowData.actualMonthUsage.data_roam_home, term);
      },
      field: 'actualMonthUsage.data_roam_home',
      render: rowData => (
        <UsageCell
          percentage={rowData.actualMonthUsage.roamPercentage}
          value={rowData.actualMonthUsage.roamString}
        />
      ),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    ...usageMonths.map((month, i) => {
      return {
        title: (
          <div>
            <div>{fairUsageTitle}</div>
            <div>{usageMonths[i].date}</div>
          </div>
        ),
        customFilterAndSearch: (term, rowData) => {
          return searchNumbers(rowData.lastMonthUsage[i].data_roam_home, term);
        },
        customSort: (a, b) => {
          return sortUsage({ a, b, i, key: 'data_roam_home' });
        },
        render: rowData => (
          <UsageCell
            percentage={rowData.lastMonthUsage[i].roamPercentage}
            value={rowData.lastMonthUsage[i].roamString}
          />
        ),
        align: 'right',
        showAll: true,
        filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
      };
    }),
    {
      title: (
        <div>
          <div>{translate('AVERAGE')}</div>
          <div>{`${translate('DATA_LIMITS.ROAMING_EU_GB').slice(0, -3)}`}</div>
          <div>
            {`${translate('LAST')} ${
              showAll ? lastMonthsCount : '3'
            } ${translate('MONTHS_SHORT')}`}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          showAll
            ? rowData.fairAverage[lastMonthsCount - 1]
            : rowData.fairAverage[2],
          term
        );
      },
      customSort: (a, b) => {
        return sortAverage({
          a,
          b,
          key: 'fairAverage',
          index: showAll ? lastMonthsCount - 1 : 2
        });
      },
      field: showAll ? `fairAverage[${lastMonthsCount - 1}]` : `fairAverage[2]`,
      align: 'right',
      headerStyle: {
        backgroundColor: AVG_COLUMN_COLOR
      },
      cellStyle: {
        backgroundColor: AVG_COLUMN_COLOR
      },
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    {
      title: (
        <div>
          <div>{`${translate('DATA_LIMITS.ROAMING_WORLD')} GB`}</div>
          <div>{actualMonth.date}</div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          rowData.currentMonthUsage.data_roam_others_string,
          term
        );
      },
      field: 'currentMonthUsage.data_roam_others_string',
      render: rowData => (
        <UsageCell
          value={rowData.actualMonthUsage.data_roam_others_string}
        />
      ),
      align: 'right',
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    },
    ...usageMonths.map((month, i) => {
      return {
        title: (
          <div>
            <div>{`${translate('DATA_LIMITS.ROAMING_WORLD')} GB`}</div>
            <div>{usageMonths[i].date}</div>
          </div>
        ),
        customFilterAndSearch: (term, rowData) => {
          return searchNumbers(
            rowData.lastMonthUsage[i].data_roam_others,
            term
          );
        },
        customSort: (a, b) => {
          return sortUsage({ a, b, i, key: 'data_roam_others' });
        },
        render: rowData => (
          <UsageCell
            value={rowData.lastMonthUsage[i].data_roam_others_string}
          />
        ),
        align: 'right',
        showAll: true,
        filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
      };
    }),
    {
      title: (
        <div>
          <div>{translate('AVERAGE')}</div>
          <div>{`${translate('DATA_LIMITS.ROAMING_WORLD')}`}</div>
          <div>
            {`${translate('LAST')} ${
              showAll ? lastMonthsCount : '3'
            } ${translate('MONTHS_SHORT')}`}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return searchNumbers(
          showAll
            ? rowData.worldAverage[lastMonthsCount - 1]
            : rowData.worldAverage[2],
          term
        );
      },
      customSort: (a, b) => {
        return sortAverage({
          a,
          b,
          key: 'worldAverage',
          index: showAll ? lastMonthsCount - 1 : 2
        });
      },
      field: showAll
        ? `worldAverage[${lastMonthsCount - 1}]`
        : `worldAverage[2]`,
      align: 'right',
      headerStyle: {
        backgroundColor: AVG_COLUMN_COLOR
      },
      cellStyle: {
        backgroundColor: AVG_COLUMN_COLOR
      },
      filterCellStyle: FILTER_CELL_STYLE_RIGHT_ALIGNED
    }
  ];

  if (!showAll) {
    return columns.filter(column => isUndefined(column.showAll));
  }

  return columns;
};

export default tableColumns;

const sortUsage = ({ a, b, i, key }) => {
  const prev = Optional(a?.lastMonthUsage?.[i]?.[key])
    .map(el => (el === '-' ? '0' : el))
    .or('0');

  const next = Optional(b?.lastMonthUsage?.[i]?.[key])
    .map(el => (el === '-' ? '0' : el))
    .or('0');

  if (prev === '0' && next === '0') {
    return 0;
  }

  return prev > next ? 1 : -1;
};

const sortAverage = ({ a, b, key, index }) => {
  const prev = Optional(a?.[key][index])
    .map(el => (el === '-' ? '0' : el))
    .or('0');

  const next = Optional(b?.[key][index])
    .map(el => (el === '-' ? '0' : el))
    .or('0');

  if (prev === '0' && next === '0') {
    return 0;
  }

  return changeAverageString(prev) > changeAverageString(next) ? 1 : -1;
};

const changeAverageString = string => {
  return Number(string.replace('.', '').replace(',', '.'));
};

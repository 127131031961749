import { JSONAPI_URLS } from '../../../consts/endpoints';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { isDefined } from '../../../utils/isDefined';

const getOrders = client => {
  return async dispatch => {
    let orders = [];

    try {
      const { data } = await getData(
        isDefined(client)
          ? `${JSONAPI_URLS.ORDERS}?include=created_by`
          : `${JSONAPI_URLS.ORDERS}?include=created_by,client&filter[client_id][gt]=0`
      );

      orders = data;
    } finally {
      dispatch(reduxActions.setOrders(orders));
    }
  };
};

export default getOrders;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchAccounts } from './actions/getAccounts';
import { isDefined } from '../../utils/isDefined';
import { actions as reduxActions } from './reducers';
import InvoicesTable from './components/InvoicesTable';

const Invoices = ({ clientId, fetchAccounts, resetAccounts }) => {
  useEffect(() => {
    if (isDefined(clientId)) {
      fetchAccounts(clientId);
    }
    return resetAccounts;
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <>
      <InvoicesTable />
    </>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    clientId: selectClient.selectedClient.id,
  };
};

const mapDispatchToProps = {
  fetchAccounts,
  resetAccounts: reduxActions.resetAccounts,
};

export default 
  connect(mapStatesToProps, mapDispatchToProps)
(Invoices);

import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, SUBMIT_NUMBER } from './types';
import { actions as reduxActions } from '../reducers';
import { actions as rightPanelReduxActions } from '../../../partials/rightPanel/reducers';
import { JSONAPI_URLS, URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { translate } from '../../../i18n/I18nProvider';
import mapNumberData from './mapNumberData';
import {
  doesArrayHasLength,
  isArrayEmpty,
  isDefined,
  isObject,
  isObjectEmpty,
  isUndefined
} from '../../../utils/isDefined';
import urlCreator from '../../../utils/urlCreator';
import store from '../../../store/store';
import Optional from '../../../utils/optional';
import findUserData from './findUserData';

export function fetchNumberData({
  searchType,
  number,
  action,
  isNonClientAdminUser,
  fromRightPanel
}) {
  return {
    type: sagaActions[SUBMIT_NUMBER],
    number,
    action,
    fromRightPanel,
    isNonClientAdminUser,
    searchType
  };
}

export function* submitNumber({
  number,
  action,
  fromRightPanel,
  isNonClientAdminUser,
  searchType
}) {
  let completeData = { number };
  const isIccSearch = searchType === 'ICC';
  let numberToSearch = number.replace(/\s|\+/g, '');

  if (isIccSearch) {
    try {
      const queryParams = {
        include: ['owner', 'client', 'client.partner', 'cell_numbers'],
        fields: {
          users: ['name', 'connect30_user_id']
        },
        filter: {
          client_id: { gt: 0 },
          icc: { match: numberToSearch },
          active: { any: true }
        }
      };

      const { data: simcardData } = yield call(
        getData,
        urlCreator(JSONAPI_URLS.SIM_CARDS, queryParams)
      );

      if (isArrayEmpty(simcardData)) {
        yield put(
          fromRightPanel
            ? rightPanelReduxActions.setNumberData({
                errors: translate('VALIDATE.NUMBER_NOT_FOUND')
              })
            : reduxActions.setNumberData({
                errors: translate('VALIDATE.NUMBER_NOT_FOUND')
              })
        );
        return (numberToSearch = void 0);
      }

      completeData = {
        ...completeData,
        simcardData: simcardData[0]
      };
      numberToSearch = completeData.simcardData.msisdn;
    } catch {}
  }

  if (isUndefined(numberToSearch)) {
    return;
  }

  if (isNonClientAdminUser) {
    try {
      const { data: lookupData } = yield call(getData, {
        options: {
          url: `${URLS.LOOKUP}/${numberToSearch}`
        },
        errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.FORBIDDEN]
      });

      completeData = { ...completeData, lookupData };
    } catch (e) {
      if (e.response.status === RESPONSE_CODES.NOT_FOUND) {
        yield put(
          fromRightPanel
            ? rightPanelReduxActions.setNumberData({
                errors: translate('VALIDATE.NUMBER_NOT_FOUND')
              })
            : reduxActions.setNumberData({
                errors: translate('VALIDATE.NUMBER_NOT_FOUND')
              })
        );
      }
    }

    try {
      if (completeData.lookupData?.our) {
        const { data: numberData } = yield call(getData, {
          options: {
            url: `${URLS.CARRIER}/cell_number/${numberToSearch}`
          },
          errorsToPass: [RESPONSE_CODES.NOT_FOUND, RESPONSE_CODES.FORBIDDEN]
        });

        const mappedData = yield call(mapNumberData, { numberData });

        completeData = {
          ...completeData,
          ...mappedData
        };
      }
    } catch (e) {}
  }

  if (!isIccSearch) {
    try {
      const queryParams = {
        include: [
          'owner',
          'client',
          'primary_card',
          'client.partner',
          'cell_numbers'
        ],
        fields: {
          users: ['name', 'connect30_user_id']
        },
        filter: {
          client_id: { gt: 0 },
          msisdn: { match: numberToSearch },
          active: { any: true }
        }
      };

      const { data: simcardData } = yield call(
        getData,
        urlCreator(JSONAPI_URLS.SIM_CARDS, queryParams)
      );
      completeData = {
        ...completeData,
        simcardData: doesArrayHasLength(simcardData) ? simcardData[0] : {}
      };
    } catch {}
  }

  try {
    let queryParams = {
      include: ['data_limit', 'data_limit.client'],
      extra_fields: { data_limits: 'accumulated_data_usage_month' },
      filter: { client_id: { gt: 0 } }
    };

    if (isDefined(completeData?.simcardData?.cell_numbers?.[0]?.id)) {
      queryParams = {
        ...queryParams,
        filter: {
          ...queryParams.filter,
          id: completeData?.simcardData?.cell_numbers?.[0]?.id
        }
      };
    } else {
      queryParams = {
        ...queryParams,
        filter: {
          ...queryParams.filter,
          number: { match: numberToSearch }
        }
      };
    }

    const { data: cellNumberData } = yield call(
      getData,
      urlCreator(JSONAPI_URLS.CELL_NUMBERS, queryParams)
    );

    const dataLimit = Optional(cellNumberData[0]?.data_limit).or({});

    let datalimitData = {};

    if (isObject(dataLimit) && Object.keys(dataLimit).length > 0) {
      datalimitData = {
        number: Optional(cellNumberData[0]?.number).or(null),
        ...Optional(cellNumberData[0]?.data_limit).or({})
      };
    }

    completeData = {
      ...completeData,
      datalimitData
    };
  } catch {}

  try {
    const mappedNumber = yield call(mapNumber, numberToSearch);
    const numberRangesQueryParams = {
      paginate: false,
      include: ['client', 'provider'],
      filter: {
        client_id: { gt: 0 },
        range_from: {
          lte: mappedNumber
        },
        range_up_to_incl: {
          gte: mappedNumber
        }
      }
    };

    const { data: externalNoData } = yield call(
      getData,
      urlCreator(JSONAPI_URLS.EXTERNAL_NO_RANGES, numberRangesQueryParams)
    );

    completeData = {
      ...completeData,
      externalNoData: doesArrayHasLength(externalNoData)
        ? externalNoData[0]
        : {}
    };
  } catch {}

  const phonebookEntryQueryParams = {
    include: ['client'],
    filter: {
      number: {
        eq: numberToSearch.length > 9 ? numberToSearch.slice(2) : numberToSearch
      }
    },
    extra_fields: { clients: 'default_address' }
  };

  const phonebookEntry = yield call(
    getData,
    urlCreator(JSONAPI_URLS.PHONE_BOOK_REGISTRATION, phonebookEntryQueryParams)
  );

  completeData = {
    ...completeData,
    phonebookEntry: Optional(phonebookEntry?.data?.[0]).or({ noEntry: true })
  };

  const user = yield call(findUserData, {
    connectClientId: completeData?.externalNoData?.client?.connect30_domain,
    number: numberToSearch
  });

  completeData = {
    ...completeData,
    user: Optional(user).or(void 0),
    number: numberToSearch
  };

  if (
    fromRightPanel &&
    isObjectEmpty(completeData.externalNoData) &&
    isObjectEmpty(completeData.simcardData)
  ) {
    yield put(
      rightPanelReduxActions.setNumberData({
        errors: `${translate('VALIDATE.NUMBER_NOT_FROM_DOMAIN')} ${Optional(
          store.getState().selectClient.selectedClient?.connect30_domain
        ).or('')}. ${translate('VALIDATE.ENTER_OTHER_NUMBER')}.`
      })
    );
  }

  yield put(
    fromRightPanel
      ? rightPanelReduxActions.setNumberData(completeData)
      : reduxActions.setNumberData(completeData)
  );
  if (isDefined(action)) {
    yield put(reduxActions.setIsDialog(true));
    yield call(action);
  }
}

function* watchSumbmitNumber() {
  yield takeLatest(sagaActions[SUBMIT_NUMBER], submitNumber);
}

export default watchSumbmitNumber();

function mapNumber(number) {
  if (isUndefined(number) || number.length > 8) {
    return number;
  }

  return `${number}`;
}

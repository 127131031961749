import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from '@material-table/core';
import flowRight from 'lodash.flowright';

import { mapTableOptions, setLocalization } from '../../../consts/tableOptions';
import { actions as reduxActions } from '../reducers';
import FilterButton from '../../../partials/tableCustomButtons/FilterButton';
import tableColumns from './tableColumns';
import {
  setTableColumns,
  updateTableColumns
} from '../../../utils/localstorageUtils';
import CustomTableTitle from '../../../partials/CustomTableTitle';
import { translate } from '../../../i18n/I18nProvider';
import customTableIcons from '../../../partials/tableIcons/customTableIcons';
import getAccounts from '../actions/getAccounts';

const AccountsTable = ({
  accounts,
  columns,
  setColumns,
  getAccounts,
  isLoading,
  clientId
}) => {
  const [filtering, setFiltering] = useState(false);

  useEffect(() => {
    getAccounts(clientId);
    // eslint-disable-next-line
  }, [clientId]);

  const tableDefaultColumns = useMemo(() => {
    return tableColumns();
  }, []);

  useEffect(() => {
    setTableColumns({
      setColumns,
      tableDefaultColumns,
      tableName: 'Accounts-1'
    });
  }, [setColumns, tableDefaultColumns]);

  const actions = useMemo(
    () => [
      {
        icon: () => <FilterButton />,
        onClick: () => {
          setFiltering(!filtering);
        },
        isFreeAction: true,
        tooltip: translate('BUTTON.FILTER')
      }
    ],
    // eslint-disable-next-line
    [filtering]
  );

  const options = useMemo(() => {
    return {
      ...mapTableOptions({ exportData: false }),
      selection: false,
      filtering
    };
  }, [filtering]);

  const localization = useMemo(() => {
    return { ...setLocalization() };
  }, []);

  const onChangeColumnHidden = useCallback(
    (columnData, hidden) => {
      updateTableColumns({
        columnData,
        hidden,
        setColumns,
        tableDefaultColumns,
        tableName: 'Accounts-1'
      });
    },
    [setColumns, tableDefaultColumns]
  );

  return (
    <>
      <MaterialTable
        title={
          <CustomTableTitle
            text="MENU.ACCOUNTS"
            isLoading={isLoading}
            icon={<i className="fas fa-book"></i>}
          />
        }
        icons={customTableIcons}
        actions={actions}
        columns={columns}
        data={accounts}
        options={options}
        localization={localization}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </>
  );
};

const mapStatesToProps = ({ accounts, loader, selectClient }) => {
  return {
    ...accounts,
    isLoading: loader.isSpinVisible,
    clientId: Number(selectClient.selectedClient.id)
  };
};

const mapDispatchToProps = {
  getAccounts,
  setColumns: reduxActions.setColumns
};

export default flowRight(connect(mapStatesToProps, mapDispatchToProps))(
  AccountsTable
);
